import { createSlice, PayloadAction, createAsyncThunk, createSelector } from '@reduxjs/toolkit'
import { Logger } from '@r1team/react-libs'

import * as service from '../../services/accounts'
import { useAppSelector } from '../../hooks/useAppSelector'
import { RootState } from '../store'

const logger = new Logger('PROFILE')

export interface State {
  data: AccountsTypes.Account | null
  loading: boolean
  error: any
}

const initialState: State = {
  data: null,
  loading: false,
  error: null,
}

export const fetchProfile = createAsyncThunk('profile/fetch', async (_, thunkAPI) => {
  try {
    return service.findMyAccount()
  } catch (e) {
    return thunkAPI.rejectWithValue('Не удалось загрузить пользователя')
  }
})

export const updateProfile = createAsyncThunk('profile/update', async (data: Partial<AccountsTypes.Account>, thunkAPI) => {
  try {
    if (data._id) {
      return service.updateAccount(data._id, data)
    }

    return thunkAPI.rejectWithValue('Не удалось обновить пользователя')
  } catch (e) {
    return thunkAPI.rejectWithValue('Не удалось обновить пользователя')
  }
})

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchProfile.fulfilled.type]: (state, action: PayloadAction<AccountsTypes.Account>) => {
      state.loading = false
      state.error = null
      state.data = action.payload

      logger.debug('data', action.payload)
    },
    [fetchProfile.pending.type]: (state) => {
      state.loading = true
    },
    [fetchProfile.rejected.type]: (state, action: PayloadAction<string>) => {
      state.loading = false
      state.error = action.payload
    },

    [updateProfile.fulfilled.type]: (state, action: PayloadAction<AccountsTypes.Account>) => {
      state.loading = false
      state.error = null
      state.data = action.payload

      logger.debug('data', action.payload)
    },
  },
})

export const selectProfile = (state: RootState): State['data'] => state.profile.data
export const selectProfileIsLoading = (state: RootState): State['loading'] => state.profile.loading
export const selectProfileRole = (state: RootState): string | null => state.profile.data?.role || null
export const selectProfilePermissions = (state: RootState): string[] => state.profile.data?.permissions || []

export const useProfile = (): Partial<AccountsTypes.Account> => {
  const profile = useAppSelector(selectProfile) || {}

  return profile
}

export const useHasRole = (role: string): boolean => {
  const profileRole = useAppSelector(selectProfileRole)

  return profileRole === role
}

export const useHasPermission = (permission: string): boolean => {
  const profilePermissions = useAppSelector(selectProfilePermissions)

  return profilePermissions.includes(permission)
}

export default profileSlice.reducer
