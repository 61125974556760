import React from 'react'
import { render, hydrate } from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import { keycloak, initOptions } from './utils/keycloak'
import { setupStore } from './state/store'
import { unregister } from './serviceWorker'
import App from './App'

const store = setupStore({})
const renderMethod = module.hot ? render : hydrate

const options = {
  ...initOptions,
}

keycloak.init(options).then((authenticated: boolean) => {
  if (authenticated) {
    return renderMethod(
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>,
      document.getElementById('root'),
    )
  }

  keycloak.login()
})

if (module.hot) {
  module.hot.accept()
}

unregister()
