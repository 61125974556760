import _omit from 'lodash/omit'
import _merge from 'lodash/merge'

import { theme } from '../styles/theme'

export const createTheme = (branding: BrandingTypes.Branding) => {
  const brandingTheme = _omit(branding.theme, '_id')

  return {
    ...theme,
    colors: _merge(theme.colors, brandingTheme),
  }
}
