import styled, { css } from 'styled-components'

import { Text as TextOriginal } from '../../Typography'

import { getTextAlpha02Color, getSecondaryTint07Color, getTextAlpha065Color, getGrayTint095Color } from '../../../../styles/utils'

import { MenuItemProps, MenuItemVariant } from '../types/MenuItem.types'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.white};
`

const getVariantStyles = (variant?: MenuItemVariant) => {
  switch (variant) {
    case 'danger':
      return css`
        color: ${({ theme }) => theme.colors.red};
      `
  }
}
const getActiveStyles = (active?: boolean) =>
  active &&
  css`
    &:after {
      display: block;
    }
  `
const getDisabledStyles = (disabled?: boolean) =>
  disabled &&
  css`
    cursor: not-allowed;
    pointer-events: none;
    color: ${getTextAlpha02Color};
  `

export const Item = styled.div<MenuItemProps>`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  align-self: stretch;
  padding: 12px 16px;
  background-color: ${({ theme }) => theme.colors.white};
  -webkit-tap-highlight-color: transparent;
  transition: background-color 0.3s ${({ theme }) => theme.easing.inOut};
  cursor: pointer;
  position: relative;

  &:after {
    content: '';
    width: 4px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: ${({ theme }) => theme.colors.gray};
    display: none;
  }

  &:hover {
    background-color: ${getSecondaryTint07Color};
  }

  ${({ variant }) => getVariantStyles(variant)}
  ${({ active }) => getActiveStyles(active)}
  ${({ disabled }) => getDisabledStyles(disabled)}
`

export const Description = styled(TextOriginal)<{ disabled?: boolean; variant?: MenuItemVariant }>`
  color: ${getTextAlpha065Color};
  width: 100%;
  font-size: 12px;
  user-select: none;

  ${({ variant }) => getVariantStyles(variant)}
  ${({ disabled }) => getDisabledStyles(disabled)}
`

export const Text = styled.div`
  display: flex;
  //align-items: center;
  //justify-content: center;
  user-select: none;
  width: 100%;
`

export const Label = styled.div`
  color: ${getTextAlpha065Color};
  font-weight: 700;
  text-overflow: ellipsis;
  white-space: nowrap;
  user-select: none;
`

export const Control = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-self: flex-start;
  pointer-events: none;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.3;
    `}
`

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${getGrayTint095Color};
`

export const SubMenuContainer = styled.div`
  ${Item} {
    padding: 8px 16px 8px 32px;
  }
`
