import * as React from 'react'

/**
 * @description SearchZoomOut icon component.
 *
 * @component
 * @example
 * ```jsx
 * <Icon icon={<SearchZoomOut />} />
 * ```
 */
export const SearchZoomOut = React.forwardRef<SVGSVGElement, React.HTMLAttributes<SVGSVGElement>>((props, ref) => (
  <svg ref={ref} data-qa="SearchZoomOut" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M9 11.7h5M11.5 21a9.5 9.5 0 1 0 0-19 9.5 9.5 0 0 0 0 19ZM22 22l-2-2"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
))

SearchZoomOut.displayName = 'SearchZoomOut'
