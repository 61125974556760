import * as React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { getUuid } from '@r1team/react-utils'

import { useAppSelector } from '../../../hooks/useAppSelector'
import { selectProfile } from '../../../state/reducers/profile'
import { NAV_LINKS, ROUTES, ROUTES_LINKS, ROUTES_NAMES, ROUTES_USER_PERMISSIONS } from '../../../constants/routes'
import { hasPermission, isMounterProfile } from '../../../utils/account'

import { Icon } from '../../ui/Icon'
import { Skeleton } from '../../ui/Skeleton'
import { Building4, Buildings, Key, Profile2User, Sms } from '../../icons'
import { Permission, Role } from '../../../constants/roles'

import Footer from './Footer'
import { SidebarProps } from '../types/Sidebar.types'
import { MenuWrapper, Menu, MenuItem, MenuItemText, SubMenu, Wrapper, MenuItemTitle } from '../styles/Sidebar.sc'

const ICONS = {
  [ROUTES.COMPANIES]: <Buildings />,
  [ROUTES.ACCOUNTS]: <Profile2User />,
  [ROUTES.MAILING]: <Sms />,
  [ROUTES.KEYS_LIST]: <Key />,
  [ROUTES.HOUSES]: <Building4 />,
  [ROUTES.COMPLEX]: <Buildings />,
}

const ACCESS_BY_ROLES = {
  [ROUTES.COMPANIES]: [Role.Mounter],
  [ROUTES.ACCOUNTS]: [Role.Owner],
  [ROUTES.MAILING]: [Role.Owner, Role.User],
  [ROUTES.HOUSES]: [Role.Owner, Role.User],
  [ROUTES.COMPLEX]: [Role.Owner, Role.User],
  [ROUTES.KEYS_LIST]: [Role.Owner, Role.User],
}

const menu = NAV_LINKS.map((key) => ({
  key,
  link: ROUTES_LINKS[key],
  name: ROUTES_NAMES[key],
  icon: ICONS[key],
  accessByRoles: ACCESS_BY_ROLES[key],
}))

const Sidebar = React.forwardRef<HTMLDivElement, SidebarProps>((props, ref) => {
  const { active = true, ...restProps } = props
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const profile = useAppSelector(selectProfile)

  const isMounter = React.useMemo(() => {
    return isMounterProfile(profile)
  }, [profile])

  const isCurrentRoute = React.useCallback(
    (path: string) => {
      return pathname === path
    },
    [pathname],
  )

  const goToLink = React.useCallback((link: string) => {
    navigate(link)
  }, [])

  const navigation = React.useMemo(() => {
    if (!profile || isMounter) {
      return null
    }

    return (
      <MenuWrapper>
        <Menu>
          {/*<MenuItem key="main" onClick={() => goToLink('/')} active={isCurrentRoute('/')} icon={<Home2 />}>*/}
          {/*  Главная*/}
          {/*</MenuItem>*/}
          {menu.map(({ key, link, name, icon, accessByRoles = [] }) => {
            return (
              hasPermission(profile, ROUTES_USER_PERMISSIONS[key]) &&
              accessByRoles.includes(profile?.role) && (
                <MenuItem key={link} size={36} onClick={() => goToLink(link)} variant={isCurrentRoute(link) ? 'primary' : 'text'}>
                  <Icon size={20} icon={icon} />
                  <MenuItemText>{name}</MenuItemText>
                </MenuItem>
              )
            )
          })}
        </Menu>
      </MenuWrapper>
    )
  }, [pathname, profile, isMounter])

  if (isMounter) return null

  return (
    <Wrapper active={active} ref={ref} data-qa="Sidebar" {...restProps}>
      {navigation}
      <Footer />
    </Wrapper>
  )
})

Sidebar.displayName = 'Sidebar'
Sidebar.defaultProps = {}

export default Sidebar
