export const runtimeConfig = {
  ENVIRONMENT: (window as any).env.ENVIRONMENT,
  VERSION: (window as any).env.VERSION,
  WEB_PATH: (window as any).env.WEB_PATH,
  API_PATH: (window as any).env.API_PATH,
  PUBLIC_SERVICE: (window as any).env.PUBLIC_SERVICE,
  OAUTH_URL: (window as any).env.OAUTH_URL,
  OAUTH_REALM: (window as any).env.OAUTH_REALM,
  OAUTH_CLIENT_ID: (window as any).env.OAUTH_CLIENT_ID,
}
