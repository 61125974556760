import React from 'react'

import { Buildings } from '../../../icons'

import { AppLoaderProps } from '../types/AppLoader.types'
import { Container, Loader, Icon, LoadingCircle, Text } from '../styles/AppLoader.sc'

const DEFAULT_TEXT = 'Загрузка...'

const AppLoader = React.forwardRef<HTMLDivElement, AppLoaderProps>((props, ref) => {
  const { text = DEFAULT_TEXT, ...restProps } = props

  return (
    <Container ref={ref} data-qa="AppLoader" {...restProps}>
      <Loader>
        <LoadingCircle />
        <Icon>
          <Buildings />
        </Icon>
        <Text>{text}</Text>
      </Loader>
    </Container>
  )
})

AppLoader.displayName = 'AppLoader'

export default AppLoader
