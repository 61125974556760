import styled, { css } from 'styled-components'

import { ContentVariant } from '../types/Content.types'

const getVariantStyles = (variant?: ContentVariant) => {
  switch (variant) {
    case 'flex':
      return css`
        display: flex;
        flex-direction: column;
      `
  }
}

export const Wrapper = styled.div<{ variant?: ContentVariant; isMounter?: boolean }>`
  width: 100%;
  margin: 0 auto;
  padding: 32px 24px;
  ${({ variant }) => getVariantStyles(variant)};

  @media screen and (min-width: ${({ theme }) => theme.mediaQuery.desktop}) {
    padding: 32px 60px;
  }

  ${({ isMounter }) =>
    !isMounter &&
    css`
      min-height: 100%;
      overflow: auto;
    `}
`
