import React from 'react'

import { hasRole } from '../../../../utils/account'
import { Role } from '../../../../constants/roles'

import { useAppSelector } from '../../../../hooks/useAppSelector'
import { useAuth } from '../../../../hooks/useAuth'
import { selectProfile, selectProfileIsLoading } from '../../../../state/reducers/profile'
import { selectBranding } from '../../../../state/reducers/branding'

import { AppLoader } from '../../AppLoader'
import type { AuthGuardProps } from '../types/AppGuard.types'
import { AppForbidden } from '../../AppForbidden'

// @ts-ignore
const AppGuard: React.FC<AuthGuardProps> = (props) => {
  const { children } = props
  const { keycloak } = useAuth()
  const profile = useAppSelector(selectProfile)
  const profileIsLoading = useAppSelector(selectProfileIsLoading)
  const branding = useAppSelector(selectBranding)

  if (keycloak.authenticated && profile === null && !profileIsLoading) {
    return <AppForbidden />
  }

  if (!keycloak.authenticated || !profile || !branding) {
    return <AppLoader />
  }

  if (!hasRole(profile, Role.Admin) && !hasRole(profile, Role.LimitsOperator)) {
    return <AppForbidden />
  }

  return children
}

AppGuard.displayName = 'AppGuard'

export default AppGuard
