import * as React from 'react'

/**
 * @description OpenPassage icon component.
 *
 * @component
 * @example
 * ```jsx
 * <Icon icon={<OpenPassage />} />
 * ```
 */
export const OpenPassage = React.forwardRef<SVGSVGElement, React.HTMLAttributes<SVGSVGElement>>((props, ref) => (
  <svg ref={ref} data-qa="OpenPassage" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M13.16 9.2a1.403 1.403 0 1 0 2.806 0 1.403 1.403 0 0 0-2.806 0Z" fill="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.484 1.269c1.035-.024 2.112.239 2.966.837l6.179 4.329c.62.432 1.142 1.098 1.507 1.798.366.7.614 1.51.614 2.267v7.28c0 2.723-2.225 4.96-4.96 4.96H6.21c-2.737 0-4.96-2.238-4.96-4.97v-7.41c0-.71.224-1.48.554-2.152.33-.672.802-1.321 1.365-1.76l5.39-4.2c.825-.641 1.89-.955 2.925-.979ZM9.48 3.432l-5.389 4.2c-.336.261-.68.707-.94 1.237-.26.53-.401 1.072-.401 1.491v7.41c0 1.908 1.557 3.47 3.46 3.47h6.747l-.118-.047a.561.561 0 0 1-.313-.73l.89-2.222a.56.56 0 0 1 .064-.118l1.18-1.652a.561.561 0 0 0 .059-.547l-.664-1.55a.112.112 0 0 0-.166-.05l-.546.365a.567.567 0 0 1-.111.058l-1.579.602a.398.398 0 0 1-.341-.027.796.796 0 0 1-.397-.69v-.076c0-.186.11-.354.282-.427l1.558-.668a.56.56 0 0 0 .21-.156l1.149-1.379a.561.561 0 0 1 .431-.201h1.008c.087 0 .173.02.25.059l2.063 1.03a.56.56 0 0 1 .118.08l1.74 1.522c.244.214.256.59.026.82l-.05.05a.561.561 0 0 1-.762.029l-1.517-1.3a.563.563 0 0 0-.114-.077l-.78-.39a.112.112 0 0 0-.154.146l.947 2.13c.032.072.049.15.049.229v2.219c0 .121.04.24.112.337l1.41 1.88a.434.434 0 0 1 .041.454.857.857 0 0 1-.078.128 3.478 3.478 0 0 0 2.396-3.291V10.5c0-.453-.157-1.023-.444-1.573-.287-.55-.665-1.004-1.035-1.262h-.001l-6.18-4.33c-.546-.383-1.294-.585-2.071-.566-.777.018-1.513.255-2.039.663Zm8.212 17.808a.249.249 0 0 1-.006-.008l-1.602-2.06a.562.562 0 0 1-.118-.345v-1.4a.112.112 0 0 0-.206-.063l-.884 1.327a.564.564 0 0 0-.054.103l-.893 2.23a.558.558 0 0 1-.153.216h3.916Z"
      fill="currentColor"
    />
  </svg>
))

OpenPassage.displayName = 'OpenPassage'
