import * as React from 'react'

/**
 * @description ArrowUp icon component.
 *
 * @component
 * @example
 * ```jsx
 * <Icon icon={<ArrowUp />} />
 * ```
 */
export const ArrowUp = React.forwardRef<SVGSVGElement, React.HTMLAttributes<SVGSVGElement>>((props, ref) => (
  <svg ref={ref} data-qa="ArrowUp" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M19.92 15.05 13.4 8.53c-.77-.77-2.03-.77-2.8 0l-6.52 6.52"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
))

ArrowUp.displayName = 'ArrowUp'
