import * as React from 'react'

/**
 * @description ClosePassage icon component.
 *
 * @component
 * @example
 * ```jsx
 * <Icon icon={<ClosePassage />} />
 * ```
 */
export const ClosePassage = React.forwardRef<SVGSVGElement, React.HTMLAttributes<SVGSVGElement>>((props, ref) => (
  <svg ref={ref} data-qa="ClosePassage" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.53 2.53a.75.75 0 0 0-1.06-1.06L18.198 4.74l-4.646-2.859a3.75 3.75 0 0 0-4.083.099L2.882 6.487A3.749 3.749 0 0 0 1.25 9.582V18c0 1.192.531 2.178.959 2.73l-.74.74a.75.75 0 1 0 1.061 1.06L14.462 10.6a1.401 1.401 0 0 0 1.5-1.5L22.53 2.53Zm-9.764.63 4.342 2.672-2.054 2.053a1.403 1.403 0 0 0-1.806 1.806l-9.966 9.966c-.247-.37-.532-.972-.532-1.657V9.582c0-.743.366-1.437.98-1.857l6.586-4.506a2.25 2.25 0 0 1 2.45-.06Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.545 11.725h1.008a.56.56 0 0 1 .25.059l2.063 1.03a.56.56 0 0 1 .118.08l1.74 1.522.026.025V8.5a.457.457 0 0 0-.134-.323l-.146-.147a.75.75 0 0 1 1.06-1.06l.147.146c.367.367.573.865.573 1.384V19a3.75 3.75 0 0 1-3.75 3.75h-12a.75.75 0 0 1 0-1.5h7.482l-.143-.057a.561.561 0 0 1-.313-.73l.89-2.222a.561.561 0 0 1 .064-.118l1.18-1.652a.561.561 0 0 0 .059-.547l-.664-1.55a.112.112 0 0 0-.166-.05l-.546.365a.56.56 0 0 1-.111.058l-1.579.602a.398.398 0 0 1-.341-.027.796.796 0 0 1-.397-.69v-.076c0-.186.11-.353.282-.427l1.558-.668a.56.56 0 0 0 .21-.156l1.149-1.379a.562.562 0 0 1 .431-.201Zm-.78 9.525H17.5a2.3 2.3 0 0 0 .194-.008l-.008-.01-1.602-2.06a.561.561 0 0 1-.118-.345v-1.4c0-.111-.144-.155-.206-.063l-.884 1.327a.561.561 0 0 0-.054.103l-.893 2.23a.558.558 0 0 1-.165.226Zm5.21-.551c.475-.413.775-1.02.775-1.699v-3.764l-.05.05a.561.561 0 0 1-.762.029l-1.517-1.3a.562.562 0 0 0-.114-.077l-.78-.39a.112.112 0 0 0-.154.146l.947 2.13a.56.56 0 0 1 .049.229v2.219c0 .121.04.24.112.337l1.41 1.88a.432.432 0 0 1 .084.21Z"
      fill="currentColor"
    />
  </svg>
))

ClosePassage.displayName = 'ClosePassage'
