import * as React from 'react'

/**
 * @description Question icon component.
 *
 * @component
 * @example
 * ```jsx
 * <Icon icon={<Question />} />
 * ```
 */
export const Question = React.forwardRef<SVGSVGElement, React.HTMLAttributes<SVGSVGElement>>((props, ref) => (
  <svg ref={ref} data-qa="Question" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M11.995 16h.009M12 22c5.5 0 10-4.5 10-10S17.5 2 12 2 2 6.5 2 12s4.5 10 10 10Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.474 13.772c-.221-.268-.208-.382-.208-.599 0-.416.1-.76.3-1.029.208-.269.512-.564.911-.885.373-.304.651-.578.834-.82.19-.244.286-.543.286-.9 0-.355-.126-.646-.378-.872-.243-.234-.586-.352-1.028-.352-.27 0-.504.057-.704.17-.2.104-.36.23-.482.377a3.245 3.245 0 0 1-.35.378c-.279.251-.68.22-.985 0-.232-.168-.2-.681-.062-.925.14-.243.156-.19.303-.39.157-.2.434-.404.834-.612.4-.209.907-.313 1.524-.313.555 0 1.05.109 1.484.326.443.208.786.499 1.03.872.242.365.364.769.364 1.211 0 .408-.07.76-.208 1.055a2.608 2.608 0 0 1-.508.743 8.23 8.23 0 0 1-.756.65c-.347.27-.599.496-.755.678a1.004 1.004 0 0 0-.235.677c0 .174.026.342-.208.61-.234.268-.781.218-1.003-.05Z"
      fill="currentColor"
    />
  </svg>
))

Question.displayName = 'Question'
