import * as React from 'react'

/**
 * @description CallOff icon component.
 *
 * @component
 * @example
 * ```jsx
 * <Icon icon={<CallOff />} />
 * ```
 */
export const CallOff = React.forwardRef<SVGSVGElement, React.HTMLAttributes<SVGSVGElement>>((props, ref) => (
  <svg ref={ref} data-qa="CallOff" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M2.204 14.195a2.162 2.162 0 0 1-.505-.805 3.504 3.504 0 0 1-.204-1.02c-.03-.62.06-1.178.276-1.694.21-.51.523-.979.943-1.399.612-.612 1.411-1.123 2.396-1.52.985-.395 2.071-.69 3.254-.876a24.415 24.415 0 0 1 3.651-.288c1.243.006 2.456.102 3.639.288 1.177.192 2.257.48 3.224.87.973.397 1.771.908 2.396 1.532.408.408.726.87.943 1.375.222.51.33 1.075.312 1.693-.006.763-.24 1.37-.685 1.814-.168.168-.372.3-.594.378a1.384 1.384 0 0 1-.739.066l-3.356-.57a2.907 2.907 0 0 1-.66-.18 1.34 1.34 0 0 1-.451-.283 1.152 1.152 0 0 1-.3-.552 2.89 2.89 0 0 1-.09-.763l-.018-.93a.454.454 0 0 0-.144-.337.768.768 0 0 0-.157-.12c-.066-.03-.12-.048-.168-.072-.306-.09-.75-.162-1.327-.21a28.991 28.991 0 0 0-1.819-.078c-.643.006-1.249.024-1.837.072-.577.048-1.015.126-1.327.222-.042.018-.096.036-.156.06a.585.585 0 0 0-.175.126.467.467 0 0 0-.144.348l-.006.907c0 .3-.03.559-.096.769a1.13 1.13 0 0 1-.3.552c-.114.114-.258.21-.444.288a3.286 3.286 0 0 1-.655.187l-3.398.576c-.265.048-.499.03-.715-.054a1.743 1.743 0 0 1-.564-.372Z"
      stroke="currentColor"
      strokeWidth="1.229"
      strokeMiterlimit="10"
    />
  </svg>
))

CallOff.displayName = 'CallOff'
