import * as React from 'react'

/**
 * @description ArrowLeftTail icon component.
 *
 * @component
 * @example
 * ```jsx
 * <Icon icon={<ArrowLeftTail />} />
 * ```
 */
export const ArrowLeftTail = React.forwardRef<SVGSVGElement, React.HTMLAttributes<SVGSVGElement>>((props, ref) => (
  <svg ref={ref} data-qa="ArrowLeftTail" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M9.57 5.93 3.5 12l6.07 6.07M20.5 12H3.67"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
))

ArrowLeftTail.displayName = 'ArrowLeftTail'
