import React from 'react'
import { useDispatch } from 'react-redux'
import { createApi } from '@reduxjs/toolkit/query/react'

import { axiosBasePublicQuery } from '../../utils/http'

export const keysApi = createApi({
  reducerPath: 'keys',
  baseQuery: axiosBasePublicQuery({ baseUrl: '/keys/v1/keys' }),
  keepUnusedDataFor: 30,
  tagTypes: ['list', 'stats'],
  endpoints: (build) => ({
    findAll: build.query<HTTPTypes.List<KeysTypes.Limit>, Record<string, string>>({
      query: (params) => ({
        url: `/limits`,
        method: 'get',
        params,
      }),
      keepUnusedDataFor: 5,
      providesTags: ['list'],
    }),
    create: build.mutation<KeysTypes.Limit, Partial<KeysTypes.Limit>>({
      query: ({ companyId, ...params }) => ({
        url: `/company/${companyId}/limits`,
        method: 'post',
        data: params,
      }),
      invalidatesTags: ['list', 'stats'],
    }),
    delete: build.mutation<KeysTypes.Limit, string>({
      query: (id) => ({
        url: `/limits/${id}`,
        method: 'delete',
      }),
      invalidatesTags: ['list', 'stats'],
    }),
    findStats: build.query<KeysTypes.Stats, string>({
      query: (companyId) => ({
        url: `/company/${companyId}/limits`,
        method: 'get',
      }),
      providesTags: ['stats'],
    }),
  }),
})

export const useListRefresh = () => {
  const dispatch = useDispatch()

  return React.useCallback(() => dispatch(keysApi.util.invalidateTags(['list'])), [dispatch])
}

export const { useFindAllQuery, useLazyFindAllQuery, useCreateMutation, useLazyFindStatsQuery, useDeleteMutation } = keysApi
