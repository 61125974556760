export enum Role {
  Admin = 'admin', // администратор lk
  Owner = 'owner', // владелец компании
  Mounter = 'mounter', // монтажник
  User = 'user', // рядовой пользователь
  LimitsOperator = 'limits-operator', // оператор лимитов
}

export enum Permission {
  CompanyCreate = 'company:create',
  CompanyView = 'company:view',
  CompanyUpdate = 'company:update',
  CompanyRemove = 'company:remove',

  ComplexCreate = 'complex:create',
  ComplexView = 'complex:view',
  ComplexUpdate = 'complex:update',
  ComplexRemove = 'complex:remove',

  AccountCreate = 'account:create',
  AccountView = 'account:view',
  AccountUpdate = 'account:update',
  AccountRemove = 'account:remove',

  DeviceCreate = 'device:create',
  DeviceView = 'device:view',
  DeviceUpdate = 'device:update',
  DeviceRemove = 'device:remove',

  KeyCreate = 'key:create',
  KeyView = 'key:view',
  KeyUpdate = 'key:update',
  KeyRemove = 'key:remove',

  LimitsCreate = 'limits:create',
  LimitsView = 'limits:view',
  LimitsUpdate = 'limits:update',
  LimitsRemove = 'limits:remove',

  MailingCreate = 'mailing:create',
  MailingView = 'mailing:view',

  ConciergeCall = 'concierge:call',
}

export const PERMISSION_GROUP_NAME = {
  COMPANY: 'company',
  COMPLEX: 'complex',
  ACCOUNT: 'account',
  DEVICE: 'device',
  KEY: 'key',
  MAILING: 'mailing',
  CONCIERGE: 'concierge',
  LIMITS: 'limits',
}

export const PERMISSIONS_GROUPS_LABELS = {
  [PERMISSION_GROUP_NAME.COMPANY]: 'Компании',
  [PERMISSION_GROUP_NAME.COMPANY]: 'Комплексы',
  [PERMISSION_GROUP_NAME.ACCOUNT]: 'Аккаунты',
  [PERMISSION_GROUP_NAME.DEVICE]: 'Камеры/устройтва',
  [PERMISSION_GROUP_NAME.KEY]: 'Ключи',
  [PERMISSION_GROUP_NAME.MAILING]: 'Сообщения',
  [PERMISSION_GROUP_NAME.CONCIERGE]: 'Консьерж',
  [PERMISSION_GROUP_NAME.LIMITS]: 'Лимиты',
}

export const PERMISSIONS_GROUPS = [PERMISSION_GROUP_NAME.DEVICE, PERMISSION_GROUP_NAME.KEY, PERMISSION_GROUP_NAME.MAILING, PERMISSION_GROUP_NAME.CONCIERGE]

export const PERMISSIONS_GROUPS_MAP = {
  [Role.Owner]: PERMISSIONS_GROUPS,
  [Role.User]: PERMISSIONS_GROUPS,
  [Role.Mounter]: [PERMISSION_GROUP_NAME.COMPANY, PERMISSION_GROUP_NAME.COMPLEX, PERMISSION_GROUP_NAME.ACCOUNT, PERMISSION_GROUP_NAME.DEVICE],
}

export const DEFAULT_PERMISSIONS = {
  [Role.User]: {
    [PERMISSION_GROUP_NAME.COMPANY]: [Permission.CompanyView],
    [PERMISSION_GROUP_NAME.COMPLEX]: [Permission.ComplexView],
  },
  [Role.Owner]: {
    [PERMISSION_GROUP_NAME.COMPANY]: [Permission.CompanyView],
    [PERMISSION_GROUP_NAME.COMPLEX]: [Permission.ComplexView],
    [PERMISSION_GROUP_NAME.ACCOUNT]: [Permission.AccountView, Permission.AccountCreate, Permission.AccountUpdate, Permission.AccountRemove],
  },
}

export const PERMISSIONS_MAP = {
  [Role.Owner]: {
    [PERMISSION_GROUP_NAME.DEVICE]: [Permission.DeviceView],
    [PERMISSION_GROUP_NAME.KEY]: [Permission.KeyView, Permission.KeyCreate, Permission.KeyRemove, Permission.KeyUpdate],
    [PERMISSION_GROUP_NAME.MAILING]: [Permission.MailingView, Permission.MailingCreate],
    [PERMISSION_GROUP_NAME.CONCIERGE]: [Permission.ConciergeCall],
    ...DEFAULT_PERMISSIONS[Role.Owner],
  },
  [Role.User]: {
    [PERMISSION_GROUP_NAME.DEVICE]: [Permission.DeviceView],
    [PERMISSION_GROUP_NAME.KEY]: [Permission.KeyView, Permission.KeyCreate, Permission.KeyRemove, Permission.KeyUpdate],
    [PERMISSION_GROUP_NAME.MAILING]: [Permission.MailingView, Permission.MailingCreate],
    [PERMISSION_GROUP_NAME.CONCIERGE]: [Permission.ConciergeCall],
    ...DEFAULT_PERMISSIONS[Role.User],
  },
}

export const PERMISSIONS_VALUES = {
  CREATE: 'create',
  VIEW: 'view',
  UPDATE: 'update',
  REMOVE: 'remove',
}

export const PERMISSIONS_VALUES_NAMES = {
  [PERMISSIONS_VALUES.CREATE]: 'Создание',
  [PERMISSIONS_VALUES.VIEW]: 'Просмотр',
  [PERMISSIONS_VALUES.UPDATE]: 'Редактирование',
  [PERMISSIONS_VALUES.REMOVE]: 'Удаление',
}
