import * as React from 'react'

/**
 * @description Unlock icon component.
 *
 * @component
 * @example
 * ```jsx
 * <Icon icon={<Unlock />} />
 * ```
 */
export const Unlock = React.forwardRef<SVGSVGElement, React.HTMLAttributes<SVGSVGElement>>((props, ref) => (
  <svg ref={ref} data-qa="Unlock" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M6 10V8c0-3.31 1-6 6-6 4.5 0 6 2 6 5m-1 15H7c-4 0-5-1-5-5v-2c0-4 1-5 5-5h10c4 0 5 1 5 5v2c0 4-1 5-5 5Zm-2.5-6a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
))

Unlock.displayName = 'Unlock'
