import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit'
import { Logger } from '@r1team/react-libs'

import * as brandingService from '../../services/branding'
import { RootState } from '../store'

const logger = new Logger('BRANDING')

export interface State {
  data: BrandingTypes.Branding | null
  loading: boolean
  error: any
}

const initialState: State = {
  data: null,
  loading: false,
  error: null,
}

export const fetchBranding = createAsyncThunk('branding/fetch', async (providerId: string, thunkAPI) => {
  try {
    return brandingService.findBranding(providerId)
  } catch (e) {
    return thunkAPI.rejectWithValue('Не удалось загрузить пользователя')
  }
})

const brandingSlice = createSlice({
  name: 'branding',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchBranding.fulfilled.type]: (state, action: PayloadAction<BrandingTypes.Branding>) => {
      state.loading = false
      state.error = null
      state.data = action.payload

      logger.debug('data', action.payload)
    },
    [fetchBranding.pending.type]: (state) => {
      state.loading = true
    },
    [fetchBranding.rejected.type]: (state, action: PayloadAction<string>) => {
      state.loading = false
      state.error = action.payload
    },
  },
})

export const selectBranding = (state: RootState) => state.branding.data

export default brandingSlice.reducer
