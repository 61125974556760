import * as React from 'react'

/**
 * @description SearchZoomIn icon component.
 *
 * @component
 * @example
 * ```jsx
 * <Icon icon={<SearchZoomIn />} />
 * ```
 */
export const SearchZoomIn = React.forwardRef<SVGSVGElement, React.HTMLAttributes<SVGSVGElement>>((props, ref) => (
  <svg ref={ref} data-qa="SearchZoomIn" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M9.2 11.7h5M11.7 14.2v-5M11.5 21a9.5 9.5 0 1 0 0-19 9.5 9.5 0 0 0 0 19ZM22 22l-2-2"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
))

SearchZoomIn.displayName = 'SearchZoomIn'
