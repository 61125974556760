import { publicClient } from '../utils/http'

const BASE_PATH = `/accounts/v1`

/**
 * Accounts
 */

export const findMyAccount = async (): Promise<AccountsTypes.Account> => {
  const { data } = await publicClient.get(`${BASE_PATH}/accounts/me`)

  return data
}

export interface IFindAllAccountsParams {
  providerId?: string
  companyId?: string
  role?: string
}
export const findAllAccounts = async (params: IFindAllAccountsParams): Promise<AccountsTypes.Account> => {
  const { data } = await publicClient.get(`${BASE_PATH}/accounts`, { params })

  return data
}

export const findOneAccount = async (id: string): Promise<AccountsTypes.Account> => {
  const { data } = await publicClient.get(`${BASE_PATH}/accounts/${id}`)

  return data
}

export const createAccount = async (params: Partial<AccountsTypes.Account>): Promise<AccountsTypes.Account> => {
  const { data } = await publicClient.post(`${BASE_PATH}/accounts`, params)

  return data
}

export const updateAccount = async (id: string, params: Partial<AccountsTypes.Account>): Promise<AccountsTypes.Account> => {
  const { data } = await publicClient.patch(`${BASE_PATH}/accounts/${id}`, params)

  return data
}

export const removeAccount = async (id: string): Promise<AccountsTypes.Account> => {
  const { data } = await publicClient.delete(`${BASE_PATH}/accounts/${id}`)

  return data
}

export const checkEmail = async (email: string): Promise<AccountsTypes.CheckResult> => {
  const { data } = await publicClient.get(`${BASE_PATH}/accounts/check/email`, { params: { email } })

  return data
}

export const checkLogin = async (username: string): Promise<AccountsTypes.CheckResult> => {
  const { data } = await publicClient.get(`${BASE_PATH}/accounts/check/login`, { params: { username } })

  return data
}

export const countAccountsByCompanyId = async (companyId: string): Promise<AccountsTypes.CountByCompany> => {
  const { data } = await publicClient.get(`${BASE_PATH}/accounts/company/${companyId}/count`)

  return data
}
