import * as React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useIsTablet } from '@r1team/react-hooks'

import { Permission } from '../../../constants/roles'
import { NAV_MOUNTER_LINKS, ROUTES, ROUTES_LINKS, ROUTES_NAMES, ROUTES_PERMISSIONS } from '../../../constants/routes'
import { selectProfile } from '../../../state/reducers/profile'
import { useAuth } from '../../../hooks/useAuth'
import { useAppSelector } from '../../../hooks/useAppSelector'
import { hasPermission, isMounterProfile } from '../../../utils/account'

import { Logout, ProfileCircle, Profile, Add, Menu as MenuIcon } from '../../icons'
import { Button } from '../../ui/Button'
import { Menu } from '../../ui/Menu'
import { Icon } from '../../ui/Icon'

import { HeaderProps } from '../types/Header.types'
import { Container, Logo, Navigation, NavigationItem, UserBlock, NavBlock, Line, User, UserMenu, UserMenuName } from '../styles/Header.sc'

const menu = NAV_MOUNTER_LINKS.map((key) => ({
  key,
  link: ROUTES_LINKS[key],
  name: ROUTES_NAMES[key],
}))

const Header = React.forwardRef<HTMLDivElement, HeaderProps>((props, ref) => {
  const { logo, onToggleMenu, ...restProps } = props
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { keycloak } = useAuth()
  const profile = useAppSelector(selectProfile)
  const isTablet = useIsTablet()

  const isMounter = React.useMemo(() => {
    return isMounterProfile(profile)
  }, [profile])

  const isCurrentRoute = React.useCallback(
    (path: string) => {
      return pathname === path ? 1 : 0
    },
    [pathname],
  )

  const handleClickLogout = React.useCallback(
    (event) => {
      event.preventDefault()

      keycloak?.logout()
    },
    [keycloak],
  )

  const handleAddClick = React.useCallback(() => {
    navigate(ROUTES_LINKS[ROUTES.COMPANIES_CREATE])
  }, [])

  const username = React.useMemo(() => {
    return keycloak?.tokenParsed?.preferred_username
  }, [keycloak])

  const navigation = React.useMemo(() => {
    if (!profile || !isMounter) {
      return null
    }

    return (
      <Navigation>
        {menu.map(
          ({ key, link, name }) =>
            hasPermission(profile, ROUTES_PERMISSIONS[key]) && (
              <NavigationItem key={link} to={link} active={isCurrentRoute(link)}>
                {name}
              </NavigationItem>
            ),
        )}
      </Navigation>
    )
  }, [pathname, profile, isMounter])

  const actions = React.useMemo(() => {
    const action = []
    if (profile && hasPermission(profile, Permission.CompanyCreate) && isMounter) {
      action.push(
        <Button variant="action" size={36} onClick={handleAddClick}>
          <Icon size={20} icon={<Add />} /> Добавить УК
        </Button>,
      )
    }

    return action.length ? action : null
  }, [profile, isMounter])

  return (
    <Container ref={ref} data-qa="Header" {...restProps}>
      <Line>
        <Logo to="/">{logo}</Logo>
        <NavBlock>{navigation}</NavBlock>
        <UserBlock>
          <User>
            <Button variant="text" size={36}>
              <Icon size={20} icon={<ProfileCircle />} /> {isTablet ? null : username}
            </Button>
            <UserMenu as={Menu}>
              <Menu.Item icon={<Profile />}>
                <UserMenuName>{username}</UserMenuName>
              </Menu.Item>
              <Menu.Item variant="danger" icon={<Logout />} onClick={handleClickLogout}>
                Выйти из профиля
              </Menu.Item>
            </UserMenu>
          </User>
          {actions}
        </UserBlock>
      </Line>
    </Container>
  )
})

Header.displayName = 'Header'
Header.defaultProps = {}

export default Header
