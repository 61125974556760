import * as React from 'react'

/**
 * @description Buildings icon component.
 *
 * @component
 * @example
 * ```jsx
 * <Icon icon={<Buildings />} />
 * ```
 */
export const Buildings = React.forwardRef<SVGSVGElement, React.HTMLAttributes<SVGSVGElement>>((props, ref) => (
  <svg ref={ref} data-qa="Buildings" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M13 22H5c-2 0-3-1-3-3v-8c0-2 1-3 3-3m8 14c-2 0-3-1-3-3m3 3h6c2 0 3-1 3-3V5c0-2-1-3-3-3h-6c-2 0-3 1-3 3M5 8h5M5 8V6c0-1.1.9-2 2-2h3.11c-.08.3-.11.63-.11 1m0 3v11m0-11V5m0 14V5m4 3v5m4-5v5M6 13v4m11 0h-2c-.55 0-1 .45-1 1v4h4v-4c0-.55-.45-1-1-1Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
))

Buildings.displayName = 'Buildings'
