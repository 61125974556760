import styled from 'styled-components'

import { Space } from '../../../components/ui/Space'

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 32px;
  width: 100%;
  height: 100%;

  @media screen and (min-width: ${({ theme }) => theme.mediaQuery.tablet}) {
    gap: 56px;
    justify-content: center;
    min-height: 640px;
    padding: 24px;
  }
`

export const Heading = styled.div`
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;

  @media screen and (min-width: ${({ theme }) => theme.mediaQuery.tablet}) {
    font-size: 40px;
    line-height: 52px;
  }
`

export const StatusImage = styled.div`
  max-height: 350px;
  max-width: 200px;
  color: ${({ theme }) => theme.colors.secondary};
  flex-shrink: 0;

  @media screen and (min-width: ${({ theme }) => theme.mediaQuery.tablet}) {
    max-width: 400px;
  }

  @media screen and (min-width: ${({ theme }) => theme.mediaQuery.desktop}) {
    max-width: 100%;
  }
`

export const Message = styled(Space)`
  gap: 24px;

  @media screen and (min-width: ${({ theme }) => theme.mediaQuery.tablet}) {
    gap: 32px;
  }
`

export const StatusMessage = styled.div`
  gap: 4px;
  font-size: 18px;
  line-height: 28px;
  text-align: center;

  @media screen and (min-width: ${({ theme }) => theme.mediaQuery.tablet}) {
    gap: 8px;
    font-size: 22px;
    line-height: 36px;
  }
`
