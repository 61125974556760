import styled, { css } from 'styled-components'

import { getSecondaryShade01Color, getTextAlpha02Color, getGrayTint09Color, getSecondaryShade015Color } from '../../../../styles/utils'

export const Container = styled.label<{
  checked?: boolean
  disabled?: boolean
}>`
  display: inline-flex;
  vertical-align: top;
  cursor: pointer;
  user-select: none;
  gap: 12px;

  ${({ disabled }) =>
    !disabled &&
    css`
      &:hover {
        ${Control} {
          border-color: ${getGrayTint09Color};
          background: ${getGrayTint09Color};
        }
      }
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      color: inherit;
      cursor: not-allowed;
      pointer-events: none;
    `}
`

export const Input = styled.input`
  position: absolute;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  opacity: 0;
`

export const Control = styled.div<{
  checked?: boolean
  disabled?: boolean
}>`
  display: flex;
  position: relative;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 20px;
  transition: all 0.3s ${({ theme }) => theme.easing.inOutQuint};
  border-width: 2px;
  border-style: solid;
  border-radius: 10px;
  border-color: ${getSecondaryShade01Color};
  background-color: ${getSecondaryShade01Color};
  will-change: background-color, border-color;

  ${({ checked }) =>
    checked &&
    css`
      border-color: ${({ theme }) => theme.colors.green};
      background-color: ${({ theme }) => theme.colors.green};
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      border-color: ${getSecondaryShade015Color};
      background-color: ${getSecondaryShade015Color};
    `}

  ${({ checked, disabled }) =>
    checked &&
    disabled &&
    css`
      border-color: ${getSecondaryShade015Color};
      background-color: ${getSecondaryShade015Color};
    `}
`

export const Handler = styled.div<{
  checked?: boolean
  disabled?: boolean
}>`
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(0);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  background: ${({ theme }) => theme.colors.white};
  will-change: left, transform;
  width: 16px;
  height: 16px;
  border-radius: 50%;

  ${({ checked }) =>
    checked &&
    css`
      left: 100%;
      transform: translateX(-100%);
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      border-color: ${getSecondaryShade015Color};
    `}
`

export const Label = styled.span<{
  disabled?: boolean
}>`
  display: flex;
  align-items: center;

  ${({ disabled }) =>
    disabled &&
    css`
      color: ${getTextAlpha02Color};
    `}
`
