import * as React from 'react'

/**
 * @description Calendar2 icon component.
 *
 * @component
 * @example
 * ```jsx
 * <Icon icon={<Calendar2 />} />
 * ```
 */
export const Calendar2 = React.forwardRef<SVGSVGElement, React.HTMLAttributes<SVGSVGElement>>((props, ref) => (
  <svg ref={ref} data-qa="Calendar2" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8 2v3m8-3v3M3.5 9.09h17m-8.505 4.61h.01m-3.71 0h.008m-.009 3h.01M21 8.5V17c0 3-1.5 5-5 5H8c-3.5 0-5-2-5-5V8.5c0-3 1.5-5 5-5h8c3.5 0 5 2 5 5Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
))

Calendar2.displayName = 'Calendar2'
