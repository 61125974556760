import { getLuminance, rgba, shade, tint } from 'polished'

import { theme } from './theme'

type Theme = typeof theme
type Props = { theme: Theme }

export const getHoverColor = (color: string) => {
  const lum = getLuminance(color)

  if (lum >= 0 && lum <= 0.2) {
    return tint(0.1, color)
  } else if (lum > 0.2 && lum <= 0.9) {
    return tint(0.3, color)
  } else {
    return shade(0.05, color)
  }
}

export const getActiveColor = (color: string) => {
  return shade(0.15, color)
}

export const getTextColor = (color: string, darkColor: string, lightColor: string) => {
  const lum = getLuminance(color)

  if (lum > 0.25) {
    return darkColor
  } else {
    return lightColor
  }
}

export const getRedTint08Color = ({ theme }: Props) => tint(0.8, theme.colors.red)
export const getRedTint04Color = ({ theme }: Props) => tint(0.4, theme.colors.red)

export const getWhiteShade008Color = ({ theme }: Props) => shade(0.08, theme.colors.white)
export const getWhiteShade022Color = ({ theme }: Props) => shade(0.22, theme.colors.white)

export const getGrayTint025Color = ({ theme }: Props) => tint(0.25, theme.colors.gray)
export const getGrayTint05Color = ({ theme }: Props) => tint(0.5, theme.colors.gray)
export const getGrayTint09Color = ({ theme }: Props) => tint(0.9, theme.colors.gray)
export const getGrayTint095Color = ({ theme }: Props) => tint(0.95, theme.colors.gray)

export const getTextAlpha080Color = ({ theme }: Props) => rgba(theme.colors.text, 0.8)
export const getTextAlpha065Color = ({ theme }: Props) => rgba(theme.colors.text, 0.65)
export const getTextAlpha02Color = ({ theme }: Props) => rgba(theme.colors.text, 0.2)
export const getTextAlpha01Color = ({ theme }: Props) => rgba(theme.colors.text, 0.1)

export const getSecondaryTint05Color = ({ theme }: Props) => tint(0.5, theme.colors.secondary)
export const getSecondaryTint07Color = ({ theme }: Props) => tint(0.7, theme.colors.secondary)
export const getSecondaryTint08Color = ({ theme }: Props) => tint(0.8, theme.colors.secondary)
export const getSecondaryShade01Color = ({ theme }: Props) => shade(0.1, theme.colors.secondary)
export const getSecondaryShade015Color = ({ theme }: Props) => shade(0.15, theme.colors.secondary)
