import styled, { css } from 'styled-components'

import { getGrayTint095Color } from '../../../styles/utils'
import { Button } from '../../ui/Button'
import { Space } from '../../ui/Space'

export const MenuWrapper = styled.div`
  flex-grow: 1;
  overflow: hidden;
  overflow-y: auto;
`

export const Menu = styled(Space)`
  padding-left: 24px;
  padding-right: 10px;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;

  @media screen and (min-width: ${({ theme }) => theme.mediaQuery.desktop}) {
    padding-left: 60px;
  }
`

export const MenuItem = styled(Button)`
  opacity: 0;
  will-change: opacity;
  max-width: 100%;
  transform: translateX(-50px);

  ${Array.from(new Array(20)).map(
    (_, i) => css`
      &:nth-child(${i + 1}) {
        transition: opacity 0.5s ${i * 0.03}s ${({ theme }) => theme.easing.inOut}, transform 0.5s ${i * 0.03}s ${({ theme }) => theme.easing.inOut};
      }
    `,
  )};

  ${({ variant }) =>
    variant === 'primary' &&
    css`
      margin-left: -16px;
    `}
`

export const MenuItemTitle = styled(MenuItem)`
  pointer-events: none;
`

export const MenuItemText = styled.div`
  max-width: 140px;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const SubMenu = styled(Menu)`
  padding-left: 28px;

  @media screen and (min-width: ${({ theme }) => theme.mediaQuery.desktop}) {
    padding-left: 28px;
  }
`

export const Wrapper = styled.div<{ active?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  padding: 24px 0;
  font-weight: 500;
  will-change: width, margin-right;
  background: ${({ theme }) => theme.colors.white};
  transition: width 0.1s ${({ theme }) => theme.easing.linear}, margin 0.1s ${({ theme }) => theme.easing.linear};
  overflow: hidden;
  z-index: 1;

  ${({ active }) =>
    active
      ? css`
          flex-shrink: 0;
          width: 250px;
          border-right: 1px solid ${getGrayTint095Color};

          @media screen and (min-width: ${({ theme }) => theme.mediaQuery.desktop}) {
            width: 268px;
            margin-right: -20px;
          }

          ${MenuItem} {
            opacity: 1;
            transform: translateX(0);
          }
        `
      : css`
          width: 0;
        `}
`
