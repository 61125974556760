import styled, { css } from 'styled-components'

import { Link } from '../../ui/Typography'
import { getGrayTint095Color } from '../../../styles/utils'

export const MenuItem = styled(Link)`
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
`

export const Wrapper = styled.div<{ isMounter?: boolean }>`
  display: flex;
  flex-shrink: 0;
  align-items: flex-start;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  padding: 24px 24px 0;

  @media screen and (min-width: ${({ theme }) => theme.mediaQuery.desktop}) {
    padding: 24px 60px 0;
  }

  ${({ isMounter }) =>
    isMounter &&
    css`
      height: 77px;
      align-items: center;
      border-top: 1px solid ${getGrayTint095Color};
      flex-direction: row-reverse;
      padding: 0 24px;

      @media screen and (min-width: ${({ theme }) => theme.mediaQuery.desktop}) {
        padding: 0 60px;
      }
    `}
`
