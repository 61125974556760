import * as React from 'react'

/**
 * @description Check icon component.
 *
 * @component
 * @example
 * ```jsx
 * <Icon icon={<Check />} />
 * ```
 */
export const Check = React.forwardRef<SVGSVGElement, React.HTMLAttributes<SVGSVGElement>>((props, ref) => (
  <svg ref={ref} data-qa="Check" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.56 8a.75.75 0 0 1 0 1.06l-7.314 7.004a.75.75 0 0 1-1.06 0L5.842 11.72a.75.75 0 0 1 1.06-1.06l3.814 3.812L17.5 8a.75.75 0 0 1 1.06 0Z"
      fill="currentColor"
    />
  </svg>
))

Check.displayName = 'Check'
