import React from 'react'

import { useAuth } from '../../../../hooks/useAuth'

import { ArrowRightTail } from '../../../icons'
import { Button } from '../../../ui/Button'
import { Space } from '../../../ui/Space'
import { Icon } from '../../../ui/Icon'

import { AppForbiddenProps } from '../types/AppForbidden.types'
import { Wrapper, Container, Heading, Message, StatusMessage, StatusImage } from '../styles/AppForbidden.sc'

const Forbidden = (props: Record<string, unknown>) => {
  return (
    <svg {...props} viewBox="0 0 1012 401" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M104.019 21C104.019 9.95431 95.0652 1 84.0195 1H20C8.95431 1 0 9.9543 0 21V188.037C0 199.082 8.95431 208.037 20 208.037H84.0193C95.065 208.037 104.019 216.991 104.019 228.037V284.518C104.019 295.564 112.974 304.518 124.019 304.518H187.037C198.082 304.518 207.037 313.472 207.037 324.518V381C207.037 392.046 215.991 401 227.037 401H291.056C302.102 401 311.056 392.046 311.056 381V116.479C311.056 105.433 302.102 96.4792 291.056 96.4792H227.037C215.991 96.4792 207.037 105.434 207.037 116.479V180.499C207.037 191.544 198.082 200.499 187.037 200.499H124.019C112.974 200.499 104.019 191.544 104.019 180.499V21Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M377.056 0C365.458 0 356.056 9.40203 356.056 21V281C356.056 292.598 365.458 302 377.056 302H435.056C446.654 302 456.056 311.402 456.056 323V379C456.056 390.598 465.458 400 477.056 400H634.056C645.654 400 655.056 390.598 655.056 379V122C655.056 110.402 645.654 101 634.056 101H577.056C565.458 101 556.056 91.598 556.056 80V21C556.056 9.40202 546.654 0 535.056 0H377.056ZM477.056 102C466.01 102 457.056 110.954 457.056 122V281C457.056 292.046 466.01 301 477.056 301H535.056C546.102 301 555.056 292.046 555.056 281V122C555.056 110.954 546.102 102 535.056 102H477.056Z"
        fill="currentColor"
      />
      <path
        d="M721.951 1C710.459 1 701.143 10.3162 701.143 21.8083V88.8874C701.143 100.379 710.459 109.696 721.951 109.696H936.857C948.349 109.696 957.665 100.379 957.665 88.8874V21.8083C957.665 10.3162 948.349 1 936.857 1H721.951Z"
        fill="currentColor"
      />
      <path
        d="M701.143 159.852C701.143 148.36 710.459 139.043 721.951 139.043H883.596C895.088 139.043 904.404 148.36 904.404 159.852V172.583C904.404 184.075 913.72 193.391 925.212 193.391H991.204C1002.7 193.391 1012.01 202.707 1012.01 214.2V380.192C1012.01 391.684 1002.7 401 991.204 401H720.864C709.372 401 700.056 391.684 700.056 380.192V313.113C700.056 301.621 709.372 292.304 720.864 292.304H882.509C894.001 292.304 903.317 282.988 903.317 271.496V268.547C903.317 257.055 894.001 247.739 882.509 247.739H721.951C710.459 247.739 701.143 238.423 701.143 226.931V159.852Z"
        fill="currentColor"
      />
    </svg>
  )
}

const AppForbidden = React.forwardRef<HTMLDivElement, AppForbiddenProps>((props, ref) => {
  const { keycloak } = useAuth()

  const handleClickLogout = React.useCallback(
    (event) => {
      event.preventDefault()

      keycloak?.logout()
    },
    [keycloak],
  )

  return (
    <Wrapper>
      <Container ref={ref} data-qa="AppForbidden" {...props}>
        <StatusImage as={Forbidden} />
        <Message direction="vertical" align="center" justify="center">
          <StatusMessage as={Space} direction="vertical" align="center" justify="center">
            <Heading>Нет доступа к этой странице</Heading>
            <div>К сожалению, не можем показать эту страницу.</div>
          </StatusMessage>
          <Button variant="action" size={48} onClick={handleClickLogout}>
            <Icon icon={<ArrowRightTail />} />
            Выйти
          </Button>
        </Message>
      </Container>
    </Wrapper>
  )
})

AppForbidden.displayName = 'AppForbidden'

export default AppForbidden
