import * as React from 'react'

/**
 * @description ArrowDownTail icon component.
 *
 * @component
 * @example
 * ```jsx
 * <Icon icon={<ArrowDownTail />} />
 * ```
 */
export const ArrowDownTail = React.forwardRef<SVGSVGElement, React.HTMLAttributes<SVGSVGElement>>((props, ref) => (
  <svg ref={ref} data-qa="ArrowDownTail" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M18.07 14.43 12 20.5l-6.07-6.07M12 3.5v16.83"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
))

ArrowDownTail.displayName = 'ArrowDownTail'
