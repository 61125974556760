import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { useAppSelector } from '../../hooks/useAppSelector'
import { RootState } from '../store'

export interface State {
  data: {
    company: Partial<CompaniesTypes.Company>
    owner: Record<string, string | string[]>
  }
  loading: boolean
  error: any
}

const initialState: State = {
  data: {
    company: {},
    owner: {},
  },
  loading: false,
  error: null,
}

const formSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    setFormData(state, action: PayloadAction<Partial<State['data']>>) {
      state.data = {
        ...state.data,
        ...action.payload,
      }
    },
    resetFormData(state) {
      state.data = initialState.data
    },
  },
})

export const selectForm = (state: RootState): State['data'] => state.form.data

export const useFormData = (): State['data'] => {
  const form = useAppSelector(selectForm)

  return form
}

export const { setFormData } = formSlice.actions
export const { resetFormData } = formSlice.actions
export default formSlice.reducer
