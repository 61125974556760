import { Permission } from './roles'

export const ROUTES = {
  PROFILE: 'PROFILE',
  COMPANIES: 'COMPANIES',
  COMPANY: 'COMPANY',
  COMPLEX: 'COMPLEX',
  COMPLEX_DETAILS: 'COMPLEX_DETAILS',
  COMPANIES_CREATE: 'COMPANIES_CREATE',
  KEYS: 'KEYS',
  KEYS_LIST: 'KEYS_LIST',
  FLATS: 'FLATS',
  OBJECTS: 'OBJECTS',
  OBJECT: 'OBJECT',
  MAILING: 'MAILING',
  ACCOUNTS: 'ACCOUNTS',
  HOUSES: 'HOUSES',
  AGREEMENTS: 'AGREEMENTS',
}

export const ROUTES_LINKS = {
  [ROUTES.PROFILE]: '/profile',
  [ROUTES.COMPANY]: '/companies/:id',
  [ROUTES.COMPANIES]: '/companies',
  [ROUTES.COMPANIES_CREATE]: '/companies/create',
  [ROUTES.COMPLEX_DETAILS]: '/complex/:complexId/:view',
  [ROUTES.COMPLEX]: '/complex/:complexId',
  [ROUTES.KEYS]: '/complex/:complexId/keys',
  [ROUTES.OBJECTS]: '/complex/:complexId/objects',
  [ROUTES.OBJECT]: '/complex/:complexId/objects/:id',
  [ROUTES.MAILING]: '/mailing',
  [ROUTES.ACCOUNTS]: '/accounts',
  [ROUTES.HOUSES]: '/houses',
  [ROUTES.FLATS]: '/houses/:houseId/flats',
  [ROUTES.KEYS_LIST]: '/keys',
  [ROUTES.AGREEMENTS]: '/agreements',
}

export const ROUTES_NAMES = {
  [ROUTES.COMPANIES]: 'Каталог УК',
  [ROUTES.OBJECTS]: 'Устройства',
  [ROUTES.MAILING]: 'Сообщения',
  [ROUTES.ACCOUNTS]: 'Сотрудники',
  [ROUTES.HOUSES]: 'Дома в управлении',
  [ROUTES.KEYS_LIST]: 'Ключи',
  [ROUTES.AGREEMENTS]: 'Правила использования',
}

export const ROUTES_PERMISSIONS = {
  [ROUTES.COMPANIES]: Permission.CompanyView,
  [ROUTES.COMPANIES_CREATE]: Permission.ComplexCreate,
  [ROUTES.COMPLEX]: Permission.ComplexView,
  [ROUTES.OBJECTS]: Permission.DeviceView,
  [ROUTES.KEYS]: Permission.KeyView,
  [ROUTES.FLATS]: Permission.CompanyView,
  [ROUTES.KEYS_LIST]: Permission.KeyView,
  [ROUTES.OBJECT]: Permission.DeviceView,
  [ROUTES.MAILING]: Permission.MailingView,
  [ROUTES.ACCOUNTS]: Permission.AccountView,
  [ROUTES.HOUSES]: Permission.CompanyView,
}

export const ROUTES_USER_PERMISSIONS = {
  ...ROUTES_PERMISSIONS,
  [ROUTES.COMPLEX_DETAILS]: Permission.ComplexView,
  [ROUTES.COMPANIES]: Permission.CompanyCreate,
}

export const NAV_MOUNTER_LINKS = [ROUTES.COMPANIES]
// export const NAV_LINKS = [ROUTES.ACCOUNTS, ROUTES.COMPANIES, ROUTES.KEYS_LIST, ROUTES.MAILING, ROUTES.HOUSES]
export const NAV_LINKS = [ROUTES.ACCOUNTS, ROUTES.COMPANIES, ROUTES.KEYS_LIST]

export const NAV_FOOTER_LINKS = [ROUTES.AGREEMENTS]
