import * as React from 'react'

/**
 * @description InfoCircle icon component.
 *
 * @component
 * @example
 * ```jsx
 * <Icon icon={<InfoCircle />} />
 * ```
 */
export const InfoCircle = React.forwardRef<SVGSVGElement, React.HTMLAttributes<SVGSVGElement>>((props, ref) => (
  <svg ref={ref} data-qa="InfoCircle" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12 8v5m-.005 3h.009M12 22c5.5 0 10-4.5 10-10S17.5 2 12 2 2 6.5 2 12s4.5 10 10 10Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
))

InfoCircle.displayName = 'InfoCircle'
