import * as React from 'react'

/**
 * @description ArrowLeft icon component.
 *
 * @component
 * @example
 * ```jsx
 * <Icon icon={<ArrowLeft />} />
 * ```
 */
export const ArrowLeft = React.forwardRef<SVGSVGElement, React.HTMLAttributes<SVGSVGElement>>((props, ref) => (
  <svg ref={ref} data-qa="ArrowLeft" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M15 19.92 8.48 13.4c-.77-.77-.77-2.03 0-2.8L15 4.08"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
))

ArrowLeft.displayName = 'ArrowLeft'
