import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from '../store'

export interface State {
  filters: Record<string, string>
  companies: Record<string, CompaniesTypes.Company>
}

const initialState: State = {
  filters: {},
  companies: {},
}

const companiesSlice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    addCompany(state, action: PayloadAction<CompaniesTypes.Company>) {
      const company = action.payload

      state.companies[company._id] = company
    },
  },
  extraReducers: {},
})

export const selectCompanies = (state: RootState) => state.companies.companies
export const { addCompany } = companiesSlice.actions

export default companiesSlice.reducer
