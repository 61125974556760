import styled, { css, keyframes } from 'styled-components'
import { Link } from 'react-router-dom'

import { getGrayTint095Color, getTextAlpha065Color } from '../../../styles/utils'
import { Icon } from '../../ui/Icon'

const animation = keyframes`
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  padding: 0 24px;
  background: ${({ theme }) => theme.colors.white};
  border-bottom: 1px solid ${getGrayTint095Color};
  height: 68px;

  @media screen and (min-width: ${({ theme }) => theme.mediaQuery.desktop}) {
    padding: 0 60px;
  }
`

export const Line = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
  height: 68px;
`

export const NavBlock = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
`

export const Logo = styled(Link)`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  //width: 173px;
  height: 48px;
  overflow: hidden;

  & > img {
    height: 48px;
    width: auto;
  }
`

export const Navigation = styled.div`
  display: flex;
  align-items: center;
  align-self: flex-start;
  width: 100%;
  height: 100%;
  gap: 16px;
`

export const NavigationItem = styled(Link)<{ active?: number }>`
  position: relative;
  display: flex;
  align-items: center;
  font-weight: 500;
  height: 100%;
  text-decoration: none;
  color: inherit;
  transition: all 0.3s ${({ theme }) => theme.easing.inOut};

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 3px;
    bottom: 0;
    left: 0;
    background-color: transparent;
    pointer-events: none;
    transition: background-color 0.3s ${({ theme }) => theme.easing.inOut};
  }

  &:hover {
    &:after {
      background-color: ${getGrayTint095Color};
    }
  }

  ${({ active }) =>
    active &&
    css`
      pointer-events: none;

      &:after {
        background-color: ${({ theme }) => theme.colors.text};
      }
    `}
`

export const UserMenu = styled.div`
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  min-width: 280px;
  box-shadow: ${({ theme }) => theme.shadow.medium};
  border-radius: 8px;
  padding: 8px 0;
  overflow: hidden;
  animation: ${animation} 300ms ${({ theme }) => theme.easing.inOut};
  z-index: ${({ theme }) => theme.zindex.dropdown};
`

export const UserMenuName = styled.div`
  color: ${getTextAlpha065Color};
`

export const User = styled.div`
  position: relative;

  &:hover {
    ${UserMenu} {
      display: block;
    }
  }
`

export const UserBlock = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  gap: 20px;
  height: 100%;
`
