import { publicClient } from '../utils/http'

const BASE_PATH = `/branding/v1`

export const findBranding = async (id: string): Promise<BrandingTypes.Branding> => {
  try {
    const { data } = await publicClient.get(`${BASE_PATH}/branding/provider/${id}`)

    return data
  } catch (err) {
    // @ts-ignore
    return {}
  }
}
