import * as React from 'react'

import { MenuProps } from './types/Menu.types'
import MenuRoot from './src/Menu'
import MenuItem from './src/MenuItem'
import SubMenu from './src/SubMenu'
import MenuDivider from './src/MenuDivider'

interface CompoundedComponent extends React.ForwardRefExoticComponent<MenuProps & React.RefAttributes<HTMLElement>> {
  Item: typeof MenuItem
  SubMenu: typeof SubMenu
  Divider: typeof MenuDivider
}

const Menu = MenuRoot as CompoundedComponent

Menu.Item = MenuItem
Menu.SubMenu = SubMenu
Menu.Divider = MenuDivider

export { Menu }
