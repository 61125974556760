import styled from 'styled-components'

export const Container = styled.main`
  display: flex;
  flex-wrap: wrap;
  //justify-content: center;
  height: 100vh;
  width: 100%;
  color: ${({ theme }) => theme.colors.text};
`
