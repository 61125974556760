import * as React from 'react'

/**
 * @description UserAdd icon component.
 *
 * @component
 * @example
 * ```jsx
 * <Icon icon={<UserAdd />} />
 * ```
 */
export const UserAdd = React.forwardRef<SVGSVGElement, React.HTMLAttributes<SVGSVGElement>>((props, ref) => (
  <svg ref={ref} data-qa="UserAdd" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M3.41 22c0-3.87 3.85-7 8.59-7 .96 0 1.89.13 2.76.37m4.73 2.61h-2.98M18 16.52v2.99M17 7A5 5 0 1 1 7 7a5 5 0 0 1 10 0Zm5 11c0 .32-.04.63-.12.93-.09.4-.25.79-.46 1.13A3.97 3.97 0 0 1 18 22a3.92 3.92 0 0 1-2.66-1.03c-.3-.26-.56-.57-.76-.91A3.92 3.92 0 0 1 14 18a3.995 3.995 0 0 1 4-4c1.18 0 2.25.51 2.97 1.33.64.71 1.03 1.65 1.03 2.67Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
))

UserAdd.displayName = 'UserAdd'
