import React from 'react'
import { ThemeProvider } from 'styled-components'
import { useLocation } from 'react-router-dom'
import { DeviceInfoProvider } from '@r1team/react-hooks'

import { GlobalStyle } from './styles/global'
import { theme } from './styles/theme'
import Router from './Router'

import { AuthProvider } from './providers/auth'
import { fetchProfile, selectProfile } from './state/reducers/profile'
import { useAppDispatch } from './hooks/useAppDispatch'

import { Header, Container, Layout } from './components/layout'
import { Logo } from './components/common/Logo'
import { AppGuard } from './components/app/AppGuard'
import { AppErrorBoundary } from './components/app/AppErrorBoundary'
import { Notifications } from './components/ui/Notifications'
import { fetchBranding, selectBranding } from './state/reducers/branding'
import { useAppSelector } from './hooks/useAppSelector'
import { createTheme } from './utils/theme'

const App = () => {
  const dispatch = useAppDispatch()
  const { pathname } = useLocation()
  const profile = useAppSelector(selectProfile)
  const branding = useAppSelector(selectBranding)

  const appTheme = React.useMemo(() => {
    if (branding) {
      return createTheme(branding)
    }

    return theme
  }, [branding])

  const logo = React.useMemo(() => {
    if (branding && branding.logo) {
      return <img src={branding.logo} alt="Логотип" />
    }

    return <Logo />
  }, [branding])

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  React.useEffect(() => {
    dispatch(fetchProfile())
  }, [dispatch])

  React.useEffect(() => {
    if (profile) {
      dispatch(fetchBranding(profile.providerId))
    }
  }, [dispatch, profile])

  return (
    <AuthProvider>
      <DeviceInfoProvider>
        <ThemeProvider theme={appTheme}>
          <GlobalStyle />
          <Layout>
            <AppGuard>
              <Header logo={logo} />
              <Container>
                <AppErrorBoundary>
                  <Router />
                </AppErrorBoundary>
              </Container>
            </AppGuard>
          </Layout>
          <Notifications />
        </ThemeProvider>
      </DeviceInfoProvider>
    </AuthProvider>
  )
}

export default App
