import * as React from 'react'

import { useAppSelector } from '../../../hooks/useAppSelector'
import { selectProfile } from '../../../state/reducers/profile'
import { hasRole } from '../../../utils/account'
import { Role } from '../../../constants/roles'

import { ContentProps } from '../types/Content.types'
import { Wrapper } from '../styles/Content.sc'

const Content = React.forwardRef<HTMLDivElement, ContentProps>((props, ref) => {
  const { children, variant, ...restProps } = props
  const profile = useAppSelector(selectProfile)

  const isMounter = React.useMemo(() => {
    if (profile) {
      return hasRole(profile, Role.Mounter)
    }

    return false
  }, [profile])

  return (
    <Wrapper isMounter={isMounter} ref={ref} variant={variant} data-qa="Content" {...restProps}>
      {children}
    </Wrapper>
  )
})

Content.displayName = 'Content'
Content.defaultProps = {
  variant: 'block',
}

export default Content
