import styled, { css } from 'styled-components'

export const Wrapper = styled.div<{ isMounter?: boolean }>`
  //display: flex;
  //justify-content: center;
  //flex-grow: 1;
  //width: 100%;
  //max-width: 1920px;
  //margin: 0 auto;
  //z-index: 1;
  //overflow: hidden;

  display: flex;
  width: 100%;
  height: calc(100vh - 68px);
`

export const Inner = styled.div<{ isMounter?: boolean }>`
  display: flex;
  //   //flex-direction: column;
  //   // flex-grow: 1;
  min-height: 100%;
  max-height: 100%;
  overflow-y: auto;
  width: 100%;

  ${({ isMounter }) =>
    isMounter &&
    css`
      justify-content: space-between;
      flex-direction: column;
    `}
`
