import * as React from 'react'

/**
 * @description HomeWifi icon component.
 *
 * @component
 * @example
 * ```jsx
 * <Icon icon={<HomeWifi />} />
 * ```
 */
export const HomeWifi = React.forwardRef<SVGSVGElement, React.HTMLAttributes<SVGSVGElement>>((props, ref) => (
  <svg ref={ref} data-qa="HomeWifi" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M10.07 2.82 3.14 8.37c-.78.62-1.28 1.93-1.11 2.91l1.33 7.96c.24 1.42 1.6 2.57 3.04 2.57h11.2c1.43 0 2.8-1.16 3.04-2.57l1.33-7.96c.16-.98-.34-2.29-1.11-2.91l-6.93-5.54c-1.07-.86-2.8-.86-3.86-.01Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.88 14.38c1.27-1.21 2.97-1.21 4.24 0M16.24 12.26a6.64 6.64 0 0 0-1.76-1.24 5.702 5.702 0 0 0-4.97 0c-.62.3-1.21.71-1.76 1.24M11.995 17h.01"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
))

HomeWifi.displayName = 'HomeWifi'
