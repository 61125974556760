import styled, { keyframes } from 'styled-components'

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const rotation = keyframes`
  from{
    transform: rotate(0deg);
  }
  to{
    transform: rotate(360deg);
  }
`

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100%;
  background-color: #fafafa;
  animation: ${fadeIn} 300ms ${({ theme }) => theme.easing.inOut};
`

export const Loader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  margin: 20px auto;
  background: #fff;
  row-gap: 11px;
  width: 300px;
  height: 300px;
  align-self: center;
  box-shadow: 0 10px 10px rgba(38, 60, 85, 0.05);
`

export const Text = styled.div`
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #181b21;
  max-width: 140px;
`

export const LoadingCircle = styled.div`
  width: 115px;
  height: 115px;
  border-top: 4px solid ${({ theme }) => theme.colors.primary};
  border-right: 3px solid transparent;
  border-radius: 50%;
  animation: ${rotation} 1s linear infinite;
`

export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #181b21;
  width: 115px;
  height: 115px;
  margin-top: -126px;
  padding: 35px;
`
