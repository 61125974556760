import React from 'react'
import { Link } from 'react-router-dom'

import { Content } from '../../../components/layout'
import { ArrowRightTail } from '../../../components/icons'
import { Button } from '../../../components/ui/Button'
import { Icon } from '../../../components/ui/Icon'
import { Space } from '../../../components/ui/Space'

import { Container, StatusImage, Message, StatusMessage, Heading } from '../styles/Error.page.sc'

const NotFound = (props: any) => {
  return (
    <svg {...props} viewBox="0 0 1012 401" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M104.47 21C104.47 9.95431 95.5154 1 84.4697 1H20.4502C9.4045 1 0.450195 9.9543 0.450195 21V188.037C0.450195 199.082 9.4045 208.037 20.4502 208.037H84.4695C95.5152 208.037 104.47 216.991 104.47 228.037V284.518C104.47 295.564 113.424 304.518 124.47 304.518H187.487C198.533 304.518 207.487 313.472 207.487 324.518V381C207.487 392.046 216.441 401 227.487 401H291.506C302.552 401 311.506 392.046 311.506 381V116.479C311.506 105.433 302.552 96.4792 291.506 96.4792H227.487C216.441 96.4792 207.487 105.434 207.487 116.479V180.499C207.487 191.544 198.533 200.499 187.487 200.499H124.47C113.424 200.499 104.47 191.544 104.47 180.499V21Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M377.506 0C365.908 0 356.506 9.40203 356.506 21V281C356.506 292.598 365.908 302 377.506 302H435.506C447.104 302 456.506 311.402 456.506 323V379C456.506 390.598 465.908 400 477.506 400H634.506C646.104 400 655.506 390.598 655.506 379V122C655.506 110.402 646.104 101 634.506 101H577.506C565.908 101 556.506 91.598 556.506 80V21C556.506 9.40202 547.104 0 535.506 0H377.506ZM477.506 102C466.461 102 457.506 110.954 457.506 122V281C457.506 292.046 466.461 301 477.506 301H535.506C546.552 301 555.506 292.046 555.506 281V122C555.506 110.954 546.552 102 535.506 102H477.506Z"
        fill="currentColor"
      />
      <path
        d="M804.526 21C804.526 9.95431 795.572 1 784.526 1H720.506C709.461 1 700.506 9.9543 700.506 21V188.037C700.506 199.082 709.461 208.037 720.506 208.037H784.526C795.571 208.037 804.526 216.991 804.526 228.037V284.518C804.526 295.564 813.48 304.518 824.526 304.518H887.543C898.589 304.518 907.543 313.472 907.543 324.518V381C907.543 392.046 916.497 401 927.543 401H991.562C1002.61 401 1011.56 392.046 1011.56 381V116.479C1011.56 105.433 1002.61 96.4792 991.562 96.4792H927.543C916.497 96.4792 907.543 105.434 907.543 116.479V180.499C907.543 191.544 898.589 200.499 887.543 200.499H824.526C813.48 200.499 804.526 191.544 804.526 180.499V21Z"
        fill="currentColor"
      />
    </svg>
  )
}

const ErrorPage = () => {
  return (
    <Content>
      <Container>
        <StatusImage as={NotFound} />
        <Message direction="vertical" align="center" justify="center">
          <StatusMessage as={Space} direction="vertical" align="center" justify="center">
            <Heading>Похоже, что мы потеряли страницу</Heading>
            <div>
              К сожалению, не нашли запрашиваемую страницу.
              <br />
              Попробуйте начать с главной.
            </div>
          </StatusMessage>
          <Link to={'/'}>
            <Button variant="action" size={48}>
              <Icon icon={<ArrowRightTail />} />
              На главную
            </Button>
          </Link>
        </Message>
      </Container>
    </Content>
  )
}

export default ErrorPage
