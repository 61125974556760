import * as React from 'react'

/**
 * @description SearchNormal icon component.
 *
 * @component
 * @example
 * ```jsx
 * <Icon icon={<SearchNormal />} />
 * ```
 */
export const SearchNormal = React.forwardRef<SVGSVGElement, React.HTMLAttributes<SVGSVGElement>>((props, ref) => (
  <svg ref={ref} data-qa="SearchNormal" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="m22 22-2-2m1-8.5a9.5 9.5 0 1 1-19 0 9.5 9.5 0 0 1 19 0Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
))

SearchNormal.displayName = 'SearchNormal'
