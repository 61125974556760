import * as React from 'react'

/**
 * @description ArrowDown icon component.
 *
 * @component
 * @example
 * ```jsx
 * <Icon icon={<ArrowDown />} />
 * ```
 */
export const ArrowDown = React.forwardRef<SVGSVGElement, React.HTMLAttributes<SVGSVGElement>>((props, ref) => (
  <svg ref={ref} data-qa="ArrowDown" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="m19.92 8.95-6.52 6.52c-.77.77-2.03.77-2.8 0L4.08 8.95"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
))

ArrowDown.displayName = 'ArrowDown'
