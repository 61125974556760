import Keycloak, { KeycloakError } from 'keycloak-js'
import * as Sentry from '@sentry/react'
import { Logger } from '@r1team/react-libs'

import { runtimeConfig } from '../config'

const logger = new Logger('AUTH')

export const initOptions = {
  checkLoginIframe: false,
  'public-client': true,
  'confidential-port': 0,
  'ssl-required': 'none',
}

// @ts-ignore
export const keycloak = new Keycloak({
  url: runtimeConfig.OAUTH_URL,
  realm: runtimeConfig.OAUTH_REALM as string,
  clientId: runtimeConfig.OAUTH_CLIENT_ID as string,
})

keycloak.onAuthSuccess = () => {
  if (keycloak.tokenParsed) {
    const { sub, aud, preferred_username, resource_access, auth_time } = keycloak.tokenParsed

    logger.debug('User data', keycloak.tokenParsed)
    logger.debug('User token', keycloak.token)

    Sentry.setUser({
      sub,
      aud,
      auth_time,
      resource_access: JSON.stringify(resource_access),
      preferred_username,
    })
  }
}

keycloak.onAuthError = (data: KeycloakError) => {
  logger.error('Auth error', data)
  Sentry.withScope((scope) => {
    scope.setTag('AuthError', (data || {}).error)
    Sentry.captureException(data)
  })
}

keycloak.onAuthRefreshSuccess = () => {
  logger.debug('Token was successfully refreshed', keycloak.token)
}

keycloak.onAuthRefreshError = () => {
  logger.debug('Token was not refreshed')
  keycloak.login()
}

export const updateToken = async () => {
  try {
    keycloak.updateToken(90)
  } catch (err) {
    Sentry.captureMessage('Failed to refresh the token, or the session has expired')
  }
}
