import { Role } from '../constants/roles'

export const hasRole = (account: AccountsTypes.Account, role: string): boolean => {
  return account.role.includes(role)
}

export const hasPermission = (account: AccountsTypes.Account, permission: string): boolean => {
  return account.permissions.includes(permission)
}

export const isMounterProfile = (account: AccountsTypes.Account | null) => {
  if (account) {
    return hasRole(account, Role.Mounter)
  }

  return false
}
