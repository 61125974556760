import * as React from 'react'

/**
 * @description Import icon component.
 *
 * @component
 * @example
 * ```jsx
 * <Icon icon={<Import />} />
 * ```
 */
export const Import = React.forwardRef<SVGSVGElement, React.HTMLAttributes<SVGSVGElement>>((props, ref) => (
  <svg ref={ref} data-qa="Import" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="m9.32 11.68 2.56 2.56 2.56-2.56M11.88 4v10.17M20 12.18c0 4.42-3 8-8 8s-8-3.58-8-8"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
))

Import.displayName = 'Import'
