import React, { createContext, Context } from 'react'
import type { KeycloakInstance } from 'keycloak-js'
import { useInterval } from '@r1team/react-hooks'

import { keycloak, updateToken } from '../utils/keycloak'

export interface AuthContextState {
  keycloak: KeycloakInstance
}

export const AuthContext: Context<AuthContextState> = createContext({
  keycloak,
})

export const AuthProvider: React.FC = (props) => {
  const { children } = props

  useInterval(() => {
    if (keycloak.isTokenExpired(90)) {
      updateToken()
    }
  }, 60 * 1000)

  React.useEffect(() => {
    if (keycloak.authenticated === false) {
      keycloak.login()
    }
  }, [keycloak.authenticated])

  return <AuthContext.Provider value={{ keycloak }}>{children}</AuthContext.Provider>
}
