import * as React from 'react'

/**
 * @description Minus icon component.
 *
 * @component
 * @example
 * ```jsx
 * <Icon icon={<Minus />} />
 * ```
 */
export const Minus = React.forwardRef<SVGSVGElement, React.HTMLAttributes<SVGSVGElement>>((props, ref) => (
  <svg ref={ref} data-qa="Minus" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M6 12h12" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
))

Minus.displayName = 'Minus'
