import * as React from 'react'

/**
 * @description Add icon component.
 *
 * @component
 * @example
 * ```jsx
 * <Icon icon={<Add />} />
 * ```
 */
export const Add = React.forwardRef<SVGSVGElement, React.HTMLAttributes<SVGSVGElement>>((props, ref) => (
  <svg ref={ref} data-qa="Add" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M6 12h12M12 18V6" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
))

Add.displayName = 'Add'
