import * as React from 'react'

import { SubMenuProps } from '../types/SubMenu.types'
import { SubMenuContainer } from '../styles/Menu.sc'

/**
 * @description SubMenu component.
 *
 * @component
 * @example
 * ```jsx
 * <Menu.SubMenu title="hint">
 *   <Menu.Item>1 год</Menu.Item>
 *   <Menu.Item>2 года</Menu.Item>
 * </Menu.SubMenu>
 * ```
 */
const SubMenu = React.forwardRef<HTMLDivElement, SubMenuProps>((props, ref) => {
  const { title, children, ...restProps } = props

  return (
    <SubMenuContainer ref={ref} data-qa="SubMenu" {...restProps}>
      {children}
    </SubMenuContainer>
  )
})

SubMenu.displayName = 'SubMenu'
SubMenu.defaultProps = {}

export default SubMenu
