import * as React from 'react'

/**
 * @description Sort icon component.
 *
 * @component
 * @example
 * ```jsx
 * <Icon icon={<Sort />} />
 * ```
 */
export const Sort = React.forwardRef<SVGSVGElement, React.HTMLAttributes<SVGSVGElement>>((props, ref) => (
  <svg ref={ref} data-qa="Sort" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M3 7h18M6 12h12M10 17h4" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
  </svg>
))

Sort.displayName = 'Sort'
