import { Action, combineReducers, configureStore, ThunkAction } from '@reduxjs/toolkit'

import profile from './reducers/profile'
import form from './reducers/form'
import branding from './reducers/branding'
import companies from './reducers/companies'

import { companiesApi } from './queries/companies'
import { keysApi } from './queries/keys'

export interface AppState {
  [companiesApi.reducerPath]: ReturnType<typeof companiesApi.reducer>
  [keysApi.reducerPath]: ReturnType<typeof keysApi.reducer>
  companies: ReturnType<typeof companies>
  branding: ReturnType<typeof branding>
  profile: ReturnType<typeof profile>
  form: ReturnType<typeof form>
}

const rootReducer = combineReducers({
  [companiesApi.reducerPath]: companiesApi.reducer,
  [keysApi.reducerPath]: keysApi.reducer,
  branding,
  companies,
  profile,
  form,
})

export const setupStore = (preloadedState: Partial<AppState>) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(companiesApi.middleware, keysApi.middleware),
  })
}

export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore['dispatch']
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>
