import * as React from 'react'

/**
 * @description CloudConcierge icon component.
 *
 * @component
 * @example
 * ```jsx
 * <Icon icon={<CloudConcierge />} />
 * ```
 */
export const CloudConcierge = React.forwardRef<SVGSVGElement, React.HTMLAttributes<SVGSVGElement>>((props, ref) => (
  <svg ref={ref} data-qa="CloudConcierge" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M16.93 6.152c-.423-2.537-1.676-4.276-3.326-5.244-1.632-.959-3.578-1.122-5.333-.66-1.755.461-3.372 1.56-4.326 3.197-.847 1.453-1.14 3.274-.61 5.335-4.72 1.135-4.43 8.46.872 8.834a.774.774 0 0 0 .053.002h6.99a.75.75 0 0 0 0-1.5H4.288c-3.717-.287-3.717-5.683-.002-5.97.475.004.938.116 1.354.328a.75.75 0 1 0 .68-1.337 4.518 4.518 0 0 0-1.454-.451c-.547-1.839-.29-3.346.375-4.485.728-1.25 1.99-2.129 3.41-2.503 1.422-.373 2.95-.227 4.193.503 1.219.716 2.237 2.035 2.6 4.16.029.48.033.748-.002.948a.692.692 0 0 1-.222.41.75.75 0 0 0 1.06 1.061c.357-.357.556-.746.638-1.208.074-.413.05-.872.02-1.343a.739.739 0 0 0-.008-.077Z"
      fill="currentColor"
    />
    <path
      d="M12.269 15.987a.752.752 0 0 1-.038-.034c-.04-.04-.12-.115-.201-.19l-.202-.19a21.226 21.226 0 0 1-2.084-2.443c-.612-.851-1.105-1.703-1.464-2.547-.35-.851-.53-1.665-.53-2.442 0-.508.09-.994.269-1.442.18-.455.463-.874.859-1.247.478-.47 1-.702 1.553-.702.21 0 .419.045.605.134.195.09.366.224.5.419l1.734 2.442c.134.187.231.359.298.523.068.157.105.314.105.455 0 .18-.052.359-.157.53a2.54 2.54 0 0 1-.418.531l-.568.59a.4.4 0 0 0-.12.299c0 .06.008.112.023.172l.03.074c.012.026.022.05.03.075.134.247.366.568.694.956.33.38.68.768 1.06 1.156 1.136 1.08 2.026 1.73 2.177 1.809l.067.03.067.03c.06.022.12.03.187.03a.41.41 0 0 0 .306-.128l.568-.56a2.29 2.29 0 0 1 .537-.418.994.994 0 0 1 .53-.157c.142 0 .292.03.456.097.165.067.336.165.523.291l2.472 1.756c.194.134.329.291.411.478.075.186.12.373.12.582a1.896 1.896 0 0 1-.187.814 3.082 3.082 0 0 1-.508.762 3.369 3.369 0 0 1-1.236.885l-.005.003a3.772 3.772 0 0 1-1.44.278c-.762 0-1.576-.18-2.435-.546a13.114 13.114 0 0 1-2.57-1.479c-.763-.482-1.402-1.07-2.018-1.676Z"
      fill="currentColor"
    />
  </svg>
))

CloudConcierge.displayName = 'CloudConcierge'
