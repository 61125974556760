import * as React from 'react'

/**
 * @description SmsTracking icon component.
 *
 * @component
 * @example
 * ```jsx
 * <Icon icon={<SmsTracking />} />
 * ```
 */
export const SmsTracking = React.forwardRef<SVGSVGElement, React.HTMLAttributes<SVGSVGElement>>((props, ref) => (
  <svg ref={ref} data-qa="SmsTracking" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M2 8.5c0-3.5 2-5 5-5h10c3 0 5 1.5 5 5v7c0 3.5-2 5-5 5H7M17 9l-3.13 2.5c-1.03.82-2.72.82-3.75 0L7 9m-5 7.5h6m-6-4h3"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
))

SmsTracking.displayName = 'SmsTracking'
