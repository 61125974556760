import * as React from 'react'

/**
 * @description LockSlash icon component.
 *
 * @component
 * @example
 * ```jsx
 * <Icon icon={<LockSlash />} />
 * ```
 */
export const LockSlash = React.forwardRef<SVGSVGElement, React.HTMLAttributes<SVGSVGElement>>((props, ref) => (
  <svg ref={ref} data-qa="LockSlash" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M10.02 17.52c.46.59 1.18.98 1.98.98a2.505 2.505 0 0 0 1.99-4.02"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.82 20.8C2.21 20.04 2 18.83 2 17v-2c0-4 1-5 5-5h10c.36 0 .69.01 1 .03 3.17.18 4 1.33 4 4.97v2c0 4-1 5-5 5H7c-.36 0-.69-.01-1-.03M6 10V8c0-3.31 1-6 6-6 4.15 0 5.54 1.38 5.9 3.56M22 2 2 22"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
))

LockSlash.displayName = 'LockSlash'
