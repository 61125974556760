import { createGlobalStyle } from 'styled-components'

import { fonts } from './fonts'
import { helpers } from './helpers'
import { theme } from './theme'

type Theme = typeof theme
type Props = { theme: Theme }

export const GlobalStyle = createGlobalStyle`
  ${fonts}
  *, *::before, *::after {
    box-sizing: border-box;
  }

  html {
    background: ${({ theme }) => theme.colors.white};
  }

  body {
    height: 100vh;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    letter-spacing: 0;
    text-rendering: optimizeLegibility;
    background: ${({ theme }) => theme.colors.white};
    color: ${({ theme }: Props) => theme.colors.text};
    font-family: CoFo Sans, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 14px;
    line-height: 20px;
    overflow: hidden;
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0;
    padding: 0;
    font-size: inherit;
    font-weight: inherit;
    line-height: 1;
  }

  menu, ol, p, small, ul {
    margin: 0;
    padding: 0;
  }

  button {
    font-family: inherit;
  }

  a {
    color: currentColor;
    text-decoration: none;
  }

  a:-webkit-any-link {
    color: currentColor;
  }

  .hidden {
    z-index: -1;
    position: absolute;
    width: 0;
    height: 0;
  }

  ${helpers}
`
