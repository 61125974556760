import * as React from 'react'

import { MenuDividerProps } from '../types/MenuDivider.types'
import { Divider } from '../styles/Menu.sc'

/**
 * @description Menu Divider component.
 *
 * @component
 * @example
 * ```jsx
 * <Menu.Divider />
 * ```
 */
const MenuDivider = React.forwardRef<HTMLDivElement, MenuDividerProps>((props, ref) => {
  return <Divider data-qa="Divider" ref={ref} {...props} />
})

MenuDivider.displayName = 'MenuDivider'

export default MenuDivider
