import styled, { keyframes } from 'styled-components'

const animation = keyframes`
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
`

export const Arrow = styled.div`
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;

  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    transform: rotate(45deg);
    border-width: 1px;
    border-style: solid;
    border-color: transparent #fff #fff transparent;
    background-color: #fff;
    //box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
  }
`

export const Bubble = styled.div`
  position: relative;
  z-index: ${({ theme }) => theme.zindex.tooltip};
  max-width: 300px;
  max-height: 341px;
  border-radius: 8px;
  padding: 8px 12px;
  background-color: #fff;
  box-shadow: ${({ theme }) => theme.shadow.medium};
  animation: ${animation} 300ms ${({ theme }) => theme.easing.inOut};

  &[data-popper-placement='top'],
  &[data-popper-placement='top-start'],
  &[data-popper-placement='top-end'] {
    margin-bottom: 7px;

    ${Arrow} {
      top: 100%;

      &::after {
        transform: translateY(-50%) rotate(45deg);
      }
    }
  }

  &[data-popper-placement='bottom'],
  &[data-popper-placement='bottom-start'],
  &[data-popper-placement='bottom-end'] {
    margin-top: 7px;
    //.shadow(true);

    ${Arrow} {
      bottom: 100%;

      &::after {
        transform: translateY(50%) rotate(225deg);
      }
    }
  }

  &[data-popper-placement='left'] {
    margin-right: 7px;

    ${Arrow} {
      left: 100%;

      &::after {
        transform: translateX(-50%) rotate(-45deg);
      }
    }
  }

  &[data-popper-placement='right'] {
    margin-left: 7px;

    ${Arrow} {
      right: 100%;

      &::after {
        transform: translateX(50%) rotate(135deg);
      }
    }
  }
`

export const Content = styled.div`
  //padding: 8px 12px;
  overflow: hidden;
  //border-radius: 9px;
  background-clip: padding-box;
  background-color: #fff;
  color: #000;
`
