import * as React from 'react'

/**
 * @description More icon component.
 *
 * @component
 * @example
 * ```jsx
 * <Icon icon={<More />} />
 * ```
 */
export const More = React.forwardRef<SVGSVGElement, React.HTMLAttributes<SVGSVGElement>>((props, ref) => (
  <svg ref={ref} data-qa="More" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M5 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2ZM19 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2ZM12 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2Z"
      stroke="currentColor"
      strokeWidth="1.5"
    />
  </svg>
))

More.displayName = 'More'
