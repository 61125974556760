import * as React from 'react'

import { Role } from '../../../constants/roles'
import { hasRole } from '../../../utils/account'
import { useAppSelector } from '../../../hooks/useAppSelector'
import { selectProfile } from '../../../state/reducers/profile'

import { ContentProps } from '../types/Container.types'
import { Wrapper, Inner } from '../styles/Container.sc'

const Container = React.forwardRef<HTMLDivElement, ContentProps>((props, ref) => {
  const { children, ...restProps } = props
  const profile = useAppSelector(selectProfile)

  const isMounter = React.useMemo(() => {
    if (profile) {
      return hasRole(profile, Role.Mounter)
    }

    return false
  }, [profile])

  return (
    <Wrapper ref={ref} data-qa="Container" isMounter={isMounter} {...restProps}>
      <Inner isMounter={isMounter}>{children}</Inner>
    </Wrapper>
  )
})

Container.displayName = 'Container'
Container.defaultProps = {}

export default Container
