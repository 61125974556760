import * as React from 'react'

/**
 * @description Menu icon component.
 *
 * @component
 * @example
 * ```jsx
 * <Icon icon={<Menu />} />
 * ```
 */
export const Menu = React.forwardRef<SVGSVGElement, React.HTMLAttributes<SVGSVGElement>>((props, ref) => (
  <svg ref={ref} data-qa="Menu" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M3 7h18M3 12h18M3 17h18" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" />
  </svg>
))

Menu.displayName = 'Menu'
