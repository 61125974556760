import * as React from 'react'

/**
 * @description DocumentText icon component.
 *
 * @component
 * @example
 * ```jsx
 * <Icon icon={<DocumentText />} />
 * ```
 */
export const DocumentText = React.forwardRef<SVGSVGElement, React.HTMLAttributes<SVGSVGElement>>((props, ref) => (
  <svg ref={ref} data-qa="DocumentText" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M22 10v5c0 5-2 7-7 7H9c-5 0-7-2-7-7V9c0-5 2-7 7-7h5m8 8h-4c-3 0-4-1-4-4V2m8 8-8-8M7 13h6m-6 4h4"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
))

DocumentText.displayName = 'DocumentText'
