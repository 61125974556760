import * as React from 'react'

/**
 * @description ArrowRightTail icon component.
 *
 * @component
 * @example
 * ```jsx
 * <Icon icon={<ArrowRightTail />} />
 * ```
 */
export const ArrowRightTail = React.forwardRef<SVGSVGElement, React.HTMLAttributes<SVGSVGElement>>((props, ref) => (
  <svg ref={ref} data-qa="ArrowRightTail" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M14.43 5.93 20.5 12l-6.07 6.07M3.5 12h16.83"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
))

ArrowRightTail.displayName = 'ArrowRightTail'
