import * as React from 'react'

/**
 * @description FolderAdd icon component.
 *
 * @component
 * @example
 * ```jsx
 * <Icon icon={<FolderAdd />} />
 * ```
 */
export const FolderAdd = React.forwardRef<SVGSVGElement, React.HTMLAttributes<SVGSVGElement>>((props, ref) => (
  <svg ref={ref} data-qa="FolderAdd" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10" strokeWidth="1.5" d="M12.06 16.5v-5M14.5 14h-5" />
    <path
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeWidth="1.5"
      d="M22 11v6c0 4-1 5-5 5H7c-4 0-5-1-5-5V7c0-4 1-5 5-5h1.5c1.5 0 1.83.44 2.4 1.2l1.5 2c.38.5.6.8 1.6.8h3c4 0 5 1 5 5Z"
    />
  </svg>
))

FolderAdd.displayName = 'FolderAdd'
