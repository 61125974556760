import * as React from 'react'

/**
 * @description ArrowRight icon component.
 *
 * @component
 * @example
 * ```jsx
 * <Icon icon={<ArrowRight />} />
 * ```
 */
export const ArrowRight = React.forwardRef<SVGSVGElement, React.HTMLAttributes<SVGSVGElement>>((props, ref) => (
  <svg ref={ref} data-qa="ArrowRight" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="m8.91 19.92 6.52-6.52c.77-.77.77-2.03 0-2.8L8.91 4.08"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
))

ArrowRight.displayName = 'ArrowRight'
